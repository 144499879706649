import { Paper } from "@liberetech/design-system";
import ActionSheet from "@liberetech/design-system/ActionSheet";
import Alert from "@liberetech/design-system/Alert";
import Button from "@liberetech/design-system/Button";
import FormInput from "@liberetech/design-system/FormInput";
import Hero from "@liberetech/design-system/Hero";
import Link from "@liberetech/design-system/Link";
import Prose from "@liberetech/design-system/Prose";
import Section from "@liberetech/design-system/Section";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";

import {
  LoginIndexDocument,
  LoginIndexQuery,
  LoginIndexQueryVariables,
} from "../pagesComponents/login/index.generated";

import { useOnlineReception } from "components/Context/OnlineReceptionContext";
import { useDevice } from "components/Context/PageContext";
import { useSession } from "components/Context/SessionContext";
import FormFieldset from "components/FormFieldset/FormFieldset";
import Loader from "components/Loader/Loader";
import { Seo } from "components/Seo/Seo";
import { Asset } from "gql/types";
import { HttpError } from "lib/client/bff";
import { ssrFetcher } from "lib/client/graphql";
import {
  identityClient,
  identityCookieName,
  LoginRequest,
} from "lib/client/identity";
import { Trans, useLanguage, useTranslation } from "lib/i18n/client";
import { Issues } from "lib/issues/issues";
import { TrackingContextPage } from "lib/tracking";
import useForm from "lib/useForm";
import withDirectChannelCommonProps from "lib/withDirectChannelCommonProps";
import { Brand, DirectChannelCommonProps } from "types";

import styles from "./login.module.css";

const QRCode = dynamic(() => import("react-qr-code"));

enum LoginStage {
  Initial = "Initial",
  RequestingEmail = "RequestingEmail",
  RequestingOTP = "RequestingOTP",
  ValidatingMToken = "ValidatingMToken",
}

enum LoginWith {
  ConfirmationNumber = "ConfirmationNumber",
  Email = "Email",
}

const statusCodesMsg = new Map([
  [400, "validation.invalidCredentials"],
  [401, "validation.loginDenied"],
]);

const loginStatusToStageMap = new Map([
  ["LOGIN_STATUS_REQUIRE_OTP_TOKEN", LoginStage.RequestingOTP],
  ["LOGIN_STATUS_REQUIRE_EMAIL", LoginStage.RequestingEmail],
]);

const Login: React.FC<LoginProps & DirectChannelCommonProps> = ({
  brand,
  asset,
}) => {
  const { t } = useTranslation("login");
  const lang = useLanguage();
  const { push, query } = useRouter();
  const { refresh: refreshSession } = useSession();
  const { isKiosk, isNativeApp, notifyNativeApp } = useDevice();
  const onlineReception = useOnlineReception();
  const magicToken =
    (typeof query.mtoken === "string" && query.mtoken) || undefined;
  const confirmationNumber =
    (typeof query.number === "string" && query.number) || undefined;
  const lastName =
    (typeof query.lastname === "string" && query.lastname) || undefined;

  const [csrfToken, setCsrfToken] = useState<string | null>(null);
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [verifyingEmail, setVerifyingEmail] = useState<string | null>(null);
  const [loginStage, setLoginStage] = useState<LoginStage>(
    magicToken ? LoginStage.ValidatingMToken : LoginStage.Initial,
  );
  const [loginWith, setLoginWith] = useState<LoginWith>(
    LoginWith.ConfirmationNumber,
  );
  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  const alertsRef = useRef<HTMLDivElement>(null);

  const { inputProps, handleSubmit, setValue } = useForm<LoginRequest>({
    translationContext: "login",
    defaultValues: {
      magicToken,
      confirmationNumber,
      lastName,
    },
  });

  const scrollIntoAlerts = () => {
    if (
      !alertsRef.current ||
      alertsRef.current.getBoundingClientRect().top >= 0
    ) {
      return;
    }
    alertsRef.current.scrollIntoView();
  };

  const doLogin = async (data: LoginRequest, csrfToken: string) => {
    try {
      const response = await identityClient(lang).login(data, csrfToken, {
        ...(isKiosk ? { skip2FA: true, skipEmailVerification: true } : {}),
        appLogin: isNativeApp,
        returnUrl: typeof query.returnUrl === "string" ? query.returnUrl : "",
      });
      if (response.statusCode == 202) {
        setProcessing(false);
        setVerifyingEmail(response.data.obfuscated_email || "");
        setLoginStage(
          loginStatusToStageMap.get(response.data.status || "") ||
            LoginStage.Initial,
        );
        scrollIntoAlerts();
        return;
      }

      notifyNativeApp("login", {
        mtoken: response.data.app_access_token,
        access_token: response.data.app_access_token,
      });
      await refreshSession();
      if (typeof query.returnUrl === "string" && query.returnUrl != "") {
        const hasLocale = query.returnUrl.match(/^\/([a-z]{2})(\/|$)/);
        push(query.returnUrl, undefined, {
          locale: hasLocale ? false : undefined,
        });
      } else {
        const locale = typeof query.locale === "string" ? query.locale : lang;
        push("/myplace", undefined, { locale });
      }
    } catch (err) {
      setProcessing(false);
      const msg = statusCodesMsg.get((err as HttpError)?.statusCode);
      setError(msg || "common:validation.unknownError");
      if (loginStage == LoginStage.ValidatingMToken) {
        setLoginStage(LoginStage.Initial);
        setValue("magicToken", undefined);
      }
      scrollIntoAlerts();
    }
  };

  useEffect(() => {
    const fetchCSRFToken = async () => {
      const response = await identityClient(lang).loginIntent();
      setCsrfToken(response.data.csrfToken);
    };
    fetchCSRFToken();
  }, []);

  useEffect(() => {
    if (magicToken && csrfToken) {
      if (
        !isNativeApp &&
        navigator.userAgent.match(/Android/) &&
        brand.enabledFeatures?.includes("app-login-redirect")
      ) {
        setTimeout(function () {
          doLogin({ magicToken }, csrfToken);
        }, 200);
        window.location.href = `${brand.id}://open?url="/login?mtoken=${magicToken}"`;
      } else {
        doLogin({ magicToken }, csrfToken);
      }
    }
  }, [csrfToken]);

  if (loginStage === LoginStage.ValidatingMToken) {
    return (
      <Section className={styles.content} background="contrast">
        <div className="grid">
          <div className={styles.formContent}>
            <FormFieldset
              title={t(`sectionTitle.stage${loginStage}`)}
              oneColumn
            >
              <Loader />
              <p>{t(`sectionDescription.stageValidatingMToken`)}</p>
            </FormFieldset>
          </div>
        </div>
      </Section>
    );
  }

  return (
    <>
      <Seo title={t("title")} />
      <Hero
        title={t("title")}
        breadcrumbs={[
          {
            title: t("common:breadcrumbs.home"),
            path: "/",
          },
        ]}
      />
      <Section className={styles.content} background="contrast">
        <form
          autoComplete="off"
          className="grid"
          onSubmit={handleSubmit(async (data) => {
            setError(null);
            setProcessing(true);
            if (!csrfToken) {
              setError("common:validation.unknownError");
              return;
            }
            await doLogin(trimLoginData(data), csrfToken);
          })}
        >
          <div className={styles.formContent} ref={alertsRef}>
            {error && (
              <Alert type="error">
                {t(error)}{" "}
                {loginStage == LoginStage.Initial &&
                  loginWith == LoginWith.ConfirmationNumber && (
                    <button
                      type="button"
                      onClick={() => {
                        setHelpOpen(true);
                        window.dataLayer.push({
                          event: "click",
                          event_category: "login_help_modal_open",
                          event_label: "from_alert",
                        });
                      }}
                    >
                      <Link>{t("moreInfo")}</Link>
                    </button>
                  )}
              </Alert>
            )}
            {query.error === "unauthenticated" && !error && !isProcessing && (
              <Alert type="error">{t("validation.unauthenticated")}</Alert>
            )}
            {query.error === "invalid_token" && !error && !isProcessing && (
              <Alert type="error">{t("validation.invalidToken")}</Alert>
            )}
            {isKiosk && (
              <Alert key="alert-kiosk-login" type="info">
                {t("common:kiosk.securityWarning")}
              </Alert>
            )}
            {verifyingEmail && loginStage == LoginStage.RequestingOTP && (
              <Alert type="info">
                {t("verificationEmailSent", { email: verifyingEmail })}
              </Alert>
            )}
            <FormFieldset
              title={t(`sectionTitle.stage${loginStage}`)}
              oneColumn
              description={t(
                `sectionDescription.stage${loginStage}${loginStage === LoginStage.Initial ? loginWith : ""}`,
              )}
            >
              {loginStage == LoginStage.Initial &&
                (loginWith == LoginWith.ConfirmationNumber ? (
                  <>
                    <FormInput
                      key="confirmationNumber"
                      {...inputProps("confirmationNumber", {
                        required: true,
                        validate: (value) => {
                          if (/^[0-9]{6}#?$/.test(value)) {
                            return "validation.notPinCode";
                          }

                          if (/^\S+@\S+\.\S+$/.test(value)) {
                            return "validation.notEmail";
                          }
                        },
                      })}
                    />
                    <FormInput
                      key="lastName"
                      {...inputProps("lastName", {
                        required: true,
                      })}
                    />
                  </>
                ) : (
                  <>
                    <FormInput
                      key="email"
                      {...inputProps("email", {
                        required: true,
                      })}
                    />
                  </>
                ))}

              {loginStage == LoginStage.RequestingEmail && (
                <FormInput
                  {...inputProps("email", {
                    required: true,
                  })}
                />
              )}

              {loginStage == LoginStage.RequestingOTP && (
                <FormInput
                  type="number"
                  {...inputProps("otpToken", {
                    required: true,
                    minLength: {
                      value: 6,
                      message: "validation.otpToken",
                    },
                    maxLength: {
                      value: 6,
                      message: "validation.otpToken",
                    },
                  })}
                />
              )}

              <Button disabled={isProcessing || !csrfToken}>
                {isProcessing
                  ? `${t("loggingIn")}...`
                  : t(`submit.stage${loginStage}`)}
              </Button>
              {loginStage == LoginStage.Initial &&
                loginWith == LoginWith.ConfirmationNumber && (
                  <div className={styles.helpButtons}>
                    <button
                      className={styles.help}
                      onClick={() => {
                        window.dataLayer.push({
                          event: "click",
                          event_category: "login_help_modal_open",
                          event_label: "from_message",
                        });
                        setHelpOpen(true);
                      }}
                      type="button"
                    >
                      <Link>{t("help.title")}</Link>
                    </button>
                    <hr className={styles.separator} />
                    <button
                      className={styles.help}
                      onClick={() => setLoginWith(LoginWith.Email)}
                      type="button"
                    >
                      <Trans
                        i18nKey="login:loginWithEmail"
                        components={[<Link />]}
                      />
                    </button>
                  </div>
                )}
              {loginStage == LoginStage.Initial &&
                loginWith == LoginWith.Email && (
                  <button
                    className={styles.help}
                    onClick={() => setLoginWith(LoginWith.ConfirmationNumber)}
                    type="button"
                  >
                    <Trans
                      i18nKey="login:loginWithConfirmationNumber"
                      components={[<Link />]}
                    />
                  </button>
                )}
            </FormFieldset>
            {isKiosk && <KioskMobileOnboarding brand={brand} asset={asset} />}
          </div>
        </form>
      </Section>
      <ActionSheet
        title={t("help.title")}
        isOpen={helpOpen}
        onRequestClose={() => {
          setHelpOpen(false);
        }}
        actions={
          <Button
            onClick={() => {
              setHelpOpen(false);
            }}
          >
            {t("common:close")}
          </Button>
        }
      >
        <Prose compact>
          <Trans
            i18nKey={"login:help.description"}
            components={{
              p: <p />,
              i: <i />,
              contactLink: (
                <ContactLink
                  onClick={() => {
                    setHelpOpen(false);
                    onlineReception.navigate({
                      type: "sendMessage",
                      trackingReason: "login-help",
                      defaultValues: {
                        title: t("common:loginSendMessageForm.title"),
                        categoryIds: [Issues.CHECKIN_ASSISTANCE_CATEGORY_ID],
                      },
                    });
                    window.dataLayer.push({
                      event: "click",
                      event_category: "login_help_modal_chat",
                    });
                  }}
                />
              ),
            }}
          />
        </Prose>
      </ActionSheet>
    </>
  );
};

const KioskMobileOnboarding: React.FC<{
  brand: Brand;
  asset: { prefix: string };
}> = ({ brand, asset }) => {
  const { t } = useTranslation("login");
  return (
    <Paper className={styles.kioskMobileOnboarding} elevation={1}>
      <h3 className={styles.kioskMobileOnboardingTitle}>
        {t("kioskMobileOnboarding.title")}
      </h3>
      <div className={styles.kioskMobileOnboardingContent}>
        <div className={styles.kioskMobileOnboardingQR}>
          <QRCode
            value={`${brand.baseUrl}/share/${asset.prefix}-qr-kiosk-login`}
            viewBox="0 0 256 256"
          />
        </div>
        <Prose compact>
          <Trans
            i18nKey="login:kioskMobileOnboarding.description"
            values={{ url: `${brand.baseUrl}/login` }}
            components={{ p: <p /> }}
          />
        </Prose>
      </div>
    </Paper>
  );
};

const ContactLink: React.FC<{ className?: string; onClick: () => void }> = ({
  children,
  onClick,
  className,
}) => {
  return (
    <button className={className} onClick={onClick}>
      <Link>{children}</Link>
    </button>
  );
};

const trimLoginData = (data: LoginRequest): LoginRequest => {
  (Object.keys(data) as Array<keyof LoginRequest>).forEach((key) => {
    const value = data[key];
    data[key] =
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      typeof value === "string" ? value.trim() : (value as any);
  });
  return data;
};

export const getServerSideProps: GetServerSideProps =
  withDirectChannelCommonProps<LoginProps>(async ({ query, req, locale }) => {
    if (!locale) {
      return { notFound: true };
    }
    if (!query.mtoken && req.cookies?.[identityCookieName]) {
      const returnUrl =
        typeof query.returnUrl === "string" ? query.returnUrl : "/myplace";
      return {
        redirect: {
          statusCode: 302,
          destination: returnUrl,
        },
      };
    }

    let asset = null as Pick<Asset, "id" | "prefix"> | null;
    if (typeof query.assetId === "string") {
      const data = await ssrFetcher<LoginIndexQuery, LoginIndexQueryVariables>(
        req,
        locale,
        LoginIndexDocument,
        { assetId: query.assetId },
      )();
      asset = data.asset;
    }

    return {
      props: {
        trackingContext: {
          page_type: "login",
          ...(asset ? { asset_id: asset.id } : {}),
        },
        asset: { prefix: asset?.prefix || "XXXX" },
      },
    };
  });

type LoginProps = {
  trackingContext: TrackingContextPage;
  asset: { prefix: string };
};

export default Login;
