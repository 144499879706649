import Paper from "@liberetech/design-system/Paper/Paper";
import classNames from "classnames";
import { ReactNode } from "react";

import styles from "./FormFieldset.module.css";

const FormFieldset: React.FC<FieldsetProps> = ({
  className,
  step,
  title,
  description,
  children,
  oneColumn,
  actions,
  ...rest
}) => (
  <Paper
    className={classNames(styles.fieldset, className, {
      [styles.fieldsetOneColumn]: oneColumn,
    })}
    elevation={1}
  >
    <h2 className={styles.fieldsetTitle}>
      {step && <span className={styles.fieldsetTitleNumber}>{step}. </span>}
      {title}
    </h2>
    <div className={styles.fieldsetContent}>
      {description && (
        <div className={styles.fieldsetDescription}>{description}</div>
      )}
      <fieldset {...rest} className={styles.fieldsetChildren}>
        {children}
      </fieldset>
    </div>
    {actions && <div className={styles.actions}>{actions}</div>}
  </Paper>
);

export default FormFieldset;

type FieldsetProps = {
  className?: string;
  step?: string;
  title: string;
  description?: string;
  oneColumn?: boolean;
  children: ReactNode;
  actions?: ReactNode;
} & React.FieldsetHTMLAttributes<HTMLFieldSetElement>;
